import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const urlSearchParams = new URLSearchParams(window.location.search);
const redirect = urlSearchParams.get('redirect');

if (redirect) {
  window.location = "https://" + redirect
}

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
