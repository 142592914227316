import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import navConfig from './config';
import { useAuthContext } from '../../../auth/useAuthContext';
import { isExpiredSubscription } from '../../../utils/formatTime';
import agencyNavConfig from './agencyConfig';

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const isExpiredSub =
    user.subscription.subscriptionId &&
    isExpiredSubscription(user.subscription.dateExpiry);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: '40px', py: 3, display: 'inline-flex' }}>
        <Logo width="160px" />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={user.avatar} />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.firstName} {user.lastName}
              </Typography>

              {window.location.hostname.includes("agency.flowbookings.io") ? <Typography></Typography> : <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user.subscription.plan}{' '}
                {isExpiredSub ? (
                  <Typography sx={{ color: 'red' }}>(Expired)</Typography>
                ) : null}
              </Typography>}
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection
        // data={window.location.hostname.includes("agency.flowbookings.io") ? agencyNavConfig : navConfig}
        data={window.location.hostname.includes("agency.flowbookings.io") ? agencyNavConfig : navConfig}
        userId={user._id}
        isExpiredSub={window.location.hostname.includes("agency.flowbookings.io") ? false : isExpiredSubscription(user.subscription.dateExpiry)}
      />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
