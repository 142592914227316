import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Card,
  Stack,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormProvider, {
  RHFTextField,
  RHFRadioGroup,
} from '../components/hook-form';
import axios from '../utils/Axios';
import { useAuthContext } from '../auth/useAuthContext';

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: 700,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(7.5, 0),
}));

const subSteps = [null, null, null];

export default function OnboardingPage() {
  const navigate = useNavigate();
  const { user, setGlobalUser } = useAuthContext();
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const defaultValues = {
    companyRole: user?.companyRole || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    avatar: user?.avatar || '',
    phoneNumber: user?.phoneNumber || '',
    companyName: user?.companyName || '',
    websiteURI: user?.websiteURI || '',
  };

  useEffect(() => {
    const isNotAllowedtoOnboard =
      defaultValues.companyRole ||
      (user.subscription && !user.subscription.subscriptionId);
    if (isNotAllowedtoOnboard) {
      navigate('/dashboard', { replace: true });
    } else {
      setShouldLoadPage(true);
    }
  }, [defaultValues]);

  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  const onSubmit = async (userData) => {
    try {
      delete userData.email;
      const response = await axios.put(`/user`, userData);
      navigate('/dashboard', { replace: true });
      setGlobalUser(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Onboarding | {localStorage.getItem("companyName") ?? "FlowBookings"} </title>
      </Helmet>
      {shouldLoadPage ? (
        <Container maxWidth="xl">
          <StyledContent>
            <Card sx={{ p: 4 }}>
              <Stepper activeStep={activeStep} sx={{ mb: 7 }}>
                {subSteps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {activeStep === 0 && <UserDetails onNext={handleNext} />}
                {activeStep === 1 && (
                  <UserCompanyRole onNext={handleNext} onBack={handleBack} />
                )}
                {activeStep === 2 && (
                  <UserChannel onSubmit={handleSubmit} onBack={handleBack} />
                )}
              </FormProvider>
            </Card>
          </StyledContent>
        </Container>
      ) : null}
    </>
  );
}

function UserDetails({ onNext }) {
  return (
    <>
      <Typography sx={{ textAlign: 'center', mb: 3 }} variant="h3">
        Let’s get you set up!
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', color: 'text.secondary', mb: 3 }}
      >
        You can update your information anytime!
      </Typography>
      <Stack spacing={3.5}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="email" label="Email address" disabled />
          <RHFTextField name="phoneNumber" label="Phone" type="text" />
        </Stack>

        <RHFTextField name="companyName" label="Website Name" />
        <RHFTextField name="websiteURI" label="Website" />

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={onNext}
        >
          Next Step
        </Button>
      </Stack>
    </>
  );
}

function UserCompanyRole({ onNext, onBack }) {
  return (
    <>
      <Typography sx={{ textAlign: 'center', mb: 3 }} variant="h3">
        What best describes your role?
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', color: 'text.secondary', mb: 3 }}
      >
        We’ll help you get started based on your responses.
      </Typography>
      <Stack spacing={3.5}>
        <RHFRadioGroup
          name="companyRole"
          label=""
          spacing={2}
          options={[
            { value: 'FREELANCER', label: 'Freelancer' },
            { value: 'AGENCY', label: 'Agency' },
            { value: 'BUSINESS_OWNER', label: 'Business Owner' },
          ]}
        />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: '100%', display: 'flex' }}
          spacing={1}
        >
          <Button
            onClick={onBack}
            fullWidth
            size="large"
            variant="outlined"
            color="inherit"
          >
            Go Back
          </Button>
          <Button fullWidth size="large" variant="contained" onClick={onNext}>
            Next Step
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

function UserChannel({ onSubmit, onBack }) {
  return (
    <>
      <Typography sx={{ textAlign: 'center', mb: 3 }} variant="h3">
        How did you hear about us?
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', color: 'text.secondary', mb: 3 }}
      >
        This will help us understand our customers better and where they are
        coming from.
      </Typography>
      <Stack spacing={3.5}>
        <RHFRadioGroup
          name="referredBy"
          label=""
          spacing={1}
          options={[
            { value: 'SOCIAL_MEDIA', label: 'Social Media' },
            { value: 'SEARCH_ENGINE', label: 'Search Engine' },
            { value: 'EMAIL', label: 'Email' },
            { value: 'ADVERTISEMENT', label: 'Advertisement' },
            { value: 'BLOG', label: 'Blog Review Article' },
            { value: 'PEER', label: 'Peer Referral' },
            { value: 'OTHER', label: 'Other' },
          ]}
        />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: '100%', display: 'flex' }}
          spacing={3}
        >
          <Button
            onClick={onBack}
            fullWidth
            size="large"
            variant="outlined"
            color="inherit"
          >
            Go Back
          </Button>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={onSubmit}
          >
            Finish
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
