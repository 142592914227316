import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { findIndex } from 'lodash';
import {
  Card,
  Alert,
  Table,
  Stack,
  Paper,
  Box,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
  Grid,
  TextField,

  // Dialog stuff
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import ServicesModal from '../sections/@dashboard/services/Modal';
import CategoriesModal from '../sections/@dashboard/services/CategoriesModal';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import axios from '../utils/Axios';
import { TableSkeleton } from '../components/skeleton';
import {
  TableListHead,
  TableListToolbar,
} from '../sections/@dashboard/services';
import { formatDuration } from '../utils/formatTime';
import palette from 'src/theme/palette';
import { useAuthContext } from 'src/auth/useAuthContext';

const TABLE_HEAD = [
  { id: 'name', label: 'Service', alignRight: false },
  { id: 'category.name', label: 'Category', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'hidden', label: 'Status', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_WITH_PRICE = [
  { id: 'name', label: 'Service', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'category.name', label: 'Category', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'hidden', label: 'Status', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitMe = orderBy.split('.');
    const first = splitMe[0];
    const second = splitMe[1];

    if (b[first][second] < a[first][second]) {
      return -1;
    }
    if (b[first][second] > a[first][second]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, category = 'all') {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  array = stabilizedThis.map((el) => el[0]);

  if (query) {
    array = array.filter(
      (service) =>
        service.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  if (category !== 'all') {
    array = array.filter((service) => service.category.name === category);
  }

  return array;
}

export default function ServicesPage() {
  const { user } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);

  const [selectedServiceRow, setSelectedServiceRow] = useState(null);
  const [selectedCategoryRow, setSelectedCategoryRow] = useState(null);
  const [categoryDialogMode, setCategoryDialogMode] = useState('');

  const [categoryFormName, setCategoryFormName] = useState('');
  const [categoryFormErr, setCategoryFormErr] = useState(null);

  const [serviceFormErr, setServiceFormErr] = useState(null);
  const [isEditService, setIsEditService] = useState(false);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [filterCategories, setFilterCategories] = useState('all');

  const [success, setSuccess] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleOpenModal = (row) => {
    setOpenPopover(null);
    setOpenModal(true);
    setSelectedServiceRow(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (openDialog) {
      setOpenDialog(false);
    }

    if (selectedServiceRow != null) {
      setSelectedServiceRow(null);
    }
  };

  const handleCloseCategoriesModal = () => {
    setOpenCategoriesModal(false);
  };

  const handleOpenCategoriesModal = () => {
    setOpenCategoriesModal(true);
  };

  const handleCategoryOpenDialog = (selectedCategory, mode) => {
    if (selectedCategory) {
      setSelectedCategoryRow(selectedCategory);
    }

    if (mode === 'edit') {
      setCategoryFormName(selectedCategory.name);
    } else if (mode === 'add') {
      setCategoryFormName('');
    }

    setCategoryDialogMode(mode);
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setSelectedCategoryRow(null);
    setCategoryFormName('');
    setCategoryDialogMode(null);
    setCategoryFormErr(null);
    setOpenCategoryDialog(false);
  };

  const handleOpenServiceDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseServiceDialog = () => {
    setServiceFormErr(null);
    setOpenDialog(false);
  };

  const handleOpenMenu = (event, rowId) => {
    setOpenPopover(event.currentTarget);
    setSelectedServiceRow({ _id: rowId });
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterCategories = (event) => {
    setPage(0);
    setFilterCategories(event.target.value);
  };

  const handleDeleteService = async () => {
    try {
      await removeService();
      handleCloseModal();
      setServiceFormErr(null);
    } catch (error) {
      setServiceFormErr(error.messages?.join('') || 'Try again later');
      console.log(error);
    }
  };

  const handleUpdateCategoryForm = (event) => {
    setCategoryFormName(event.target.value);
  };

  const getServicesAndCats = useCallback(async () => {
    try {
      const [servicesResponse, categoriesResponse] = await Promise.all([
        axios.get('/services'),
        axios.get('/categories'),
      ]);
      setServices(servicesResponse.data.services);
      setCategories(categoriesResponse.data.categories);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const createService = useCallback(
    async (serviceData) => {
      const response = await axios.post('/services', serviceData);
      setServices([...services, response.data.service]);

      if (Number(serviceData.hidden) !== Number(response.data.service.hidden)) {
        setSuccess({
          bg: 'warning',
          message:
            "You've reached the maximum number of active services for your plan. You can still create a service but it won't be active until you deactivate one of the other services",
        });
      } else {
        setSuccess({
          bg: 'success',
          message: 'Service Created.',
        });
      }

      setTimeout(() => setSuccess(null), 6000);
    },
    [services, setServices]
  );

  const updateService = useCallback(
    async (serviceData) => {
      if (!selectedServiceRow && !selectedServiceRow._id)
        throw new Error('serivice is not selected');
      const serviceId = selectedServiceRow._id;
      const response = await axios.put(`/services/${serviceId}`, serviceData);
      const mutatedServices = [...services];
      const servicIndex = findIndex(mutatedServices, { _id: serviceId });
      mutatedServices.splice(servicIndex, 1, response.data.service);
      setServices(mutatedServices);
    },
    [services, setServices, selectedServiceRow]
  );

  const removeService = useCallback(async () => {
    if (!selectedServiceRow && !selectedServiceRow._id)
      throw new Error('Service is not selected');
    const serviceId = selectedServiceRow._id;
    const response = await axios.delete(`/services/${selectedServiceRow._id}`);
    if (response.status === 200) {
      const mutatedServices = [...services];
      const customerIndex = findIndex(mutatedServices, { _id: serviceId });
      mutatedServices.splice(customerIndex, 1);
      setServices(mutatedServices);
    }
  }, [services, selectedServiceRow, setServices]);

  const updateCategory = useCallback(async () => {
    if (!selectedCategoryRow && !selectedCategoryRow._id)
      throw new Error('Category is not found');
    const categoryId = selectedCategoryRow._id;
    await axios.put(`/categories/${categoryId}`, { name: categoryFormName });
    getServicesAndCats();
  }, [categories, setCategories, selectedCategoryRow, categoryFormName]);

  const createCategory = useCallback(async () => {
    const response = await axios.post(`/categories`, {
      name: categoryFormName,
    });
    setCategories([...categories, response.data.category]);
  }, [categories, setCategories, selectedCategoryRow, categoryFormName]);

  const handleUpdateCategory = async () => {
    try {
      await updateCategory();
      handleCloseCategoryDialog();
      setCategoryFormErr(null);
    } catch (error) {
      setCategoryFormErr(error.messages?.join('') || 'Try again later');
    }
  };

  const handleAddCategory = async () => {
    try {
      await createCategory();
      handleCloseCategoryDialog();
      setCategoryFormErr(null);
    } catch (error) {
      setCategoryFormErr(error.messages?.join('') || 'Try again later');
    }
  };

  const handleDeleteCategory = async () => {
    try {
      await removeCategory();
      handleCloseCategoryDialog();
      setCategoryFormErr(null);
    } catch (error) {
      setCategoryFormErr(error.messages?.join('') || 'Try again later');
    }
  };

  const removeCategory = useCallback(async () => {
    if (!selectedCategoryRow && !selectedCategoryRow._id)
      throw new Error('Category is not selected');
    const categoryId = selectedCategoryRow._id;
    const response = await axios.delete(`/categories/${categoryId}`);
    if (response.status === 200) {
      const mutatedCategories = [...categories];
      const customerIndex = findIndex(mutatedCategories, { _id: categoryId });
      mutatedCategories.splice(customerIndex, 1);
      setCategories(mutatedCategories);
    }
  }, [categories, selectedCategoryRow, setCategories]);

  useEffect(() => {
    getServicesAndCats();
  }, []);

  const filteredServices = applySortFilter(
    services,
    getComparator(order, orderBy),
    filterName,
    filterCategories
  );

  return (
    <>
      <Helmet>
        <title>Services | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Services
          </Typography>
          <Grid>
            <Button
              sx={{
                fontSize: '14px',
                backgroundColor: palette.primary.li,
                color: palette.primary.main,
                padding: '6px 16px',
                mr: 2,
              }}
              onClick={handleOpenCategoriesModal}
            >
              Manage Categories
            </Button>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={(event) => {
                setIsEditService(false);
                handleOpenModal(event, null);
              }}
            >
              New Service
            </Button>
          </Grid>
        </Stack>

        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}
        <Card>
          <TableListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            categories={categories}
            onFilterCategories={handleFilterCategories}
            filterCategories={filterCategories}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={user.config.enablePayments ? TABLE_HEAD_WITH_PRICE : TABLE_HEAD}
                  rowCount={services.length}
                  onRequestSort={handleRequestSort}
                />
                {isLoading &&
                  [...Array(5)].map(() => <TableSkeleton shouldShowImage />)}
                {!isLoading && services.length ? (
                  <TableBody>
                    {filteredServices
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          _id,
                          name,
                          duration,
                          previewImage,
                          hidden,
                          category,
                        } = row;
                        return (
                          <TableRow hover key={_id}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ width: 300 }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {previewImage ? (
                                  <Box
                                    component="img"
                                    alt={name}
                                    src={previewImage}
                                    sx={{
                                      width: 48,
                                      height: 48,
                                      borderRadius: 1.5,
                                      flexShrink: 0,
                                    }}
                                  />
                                ) : (
                                  <Skeleton
                                    sx={{
                                      width: 48,
                                      height: 48,
                                      borderRadius: 1.5,
                                      flexShrink: 0,
                                    }}
                                    animation={false}
                                    variant="rectangular"
                                  />
                                )}
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {user.config.enablePayments && (
                              <TableCell>
                                {row.price ? `${user.config.currency} ${row.price}` : 'Free'}
                              </TableCell>
                            )}
                            <TableCell>{category.name}</TableCell>
                            <TableCell align="left">
                              {formatDuration(duration)}
                            </TableCell>
                            <TableCell align="left">
                              <Label color={(hidden && 'error') || 'success'}>
                                {hidden ? 'Inactive' : 'Active'}
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    navigator.clipboard.writeText(_id);
                                  }}
                                >
                                <Typography variant="subtitle2" noWrap>
                                  Copy ID
                                </Typography>
                                </Button>
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, _id)}
                              >
                                <Iconify icon="eva:more-vertical-fill" />
                              </IconButton>
                            </TableCell>
                            <Popover
                              open={Boolean(
                                openPopover &&
                                selectedServiceRow &&
                                selectedServiceRow._id === _id
                              )}
                              anchorEl={openPopover}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 140,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleOpenModal(row);
                                  setIsEditService(true);
                                }}
                              >
                                <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>

                              <MenuItem
                                sx={{ color: 'error.main' }}
                                onClick={() => {
                                  handleOpenServiceDialog();
                                  setSelectedServiceRow(row);
                                }}
                              >
                                <Iconify
                                  icon="eva:trash-2-outline"
                                  sx={{ mr: 2 }}
                                />
                                Delete
                              </MenuItem>
                            </Popover>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  ''
                )}
                {!isLoading && !filteredServices.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Services
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredServices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <ServicesModal
        service={selectedServiceRow}
        categories={categories}
        isEdit={isEditService}
        openModal={openModal}
        onCloseModal={handleCloseModal}
        onOpenDialog={handleOpenServiceDialog}
        createService={createService}
        updateService={updateService}
      />

      <CategoriesModal
        categories={categories}
        openModal={openCategoriesModal}
        onCloseModal={handleCloseCategoriesModal}
        onOpenCategoryDialog={handleCategoryOpenDialog}
      />

      {openCategoryDialog && (
        <Dialog fullWidth maxWidth="xs" open={openCategoryDialog}>
          {categoryDialogMode === 'add' && (
            <>
              <DialogTitle sx={{ pb: 2 }}>Add Category</DialogTitle>
              <TextField
                value={categoryFormName}
                sx={{ width: '100%', p: 2 }}
                inputProps={{ onChange: handleUpdateCategoryForm }}
              />
              {categoryFormErr && (
                <Typography sx={{ pl: 2 }} type="body2" color="error">
                  {categoryFormErr}
                </Typography>
              )}
              <DialogActions>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAddCategory}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleCloseCategoryDialog}
                >
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
          {categoryDialogMode === 'delete' && (
            <>
              <DialogTitle sx={{ pt: 2 }}>Delete</DialogTitle>
              <DialogContent sx={{ typography: 'body2' }}>
                Are you sure want to delete category {selectedCategoryRow.name}{' '}
                ?
              </DialogContent>
              {categoryFormErr && (
                <Typography sx={{ pl: 2 }} type="body2" color="error">
                  {categoryFormErr}
                </Typography>
              )}
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeleteCategory()}
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleCloseCategoryDialog}
                >
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
          {categoryDialogMode === 'edit' && (
            <>
              <DialogTitle sx={{ pb: 2 }}>Edit Category</DialogTitle>
              <TextField
                value={categoryFormName}
                sx={{ width: '100%', p: 2 }}
                inputProps={{ onChange: handleUpdateCategoryForm }}
              />
              {categoryFormErr && (
                <Typography sx={{ pl: 2 }} type="body2" color="error">
                  {categoryFormErr}
                </Typography>
              )}
              <DialogActions>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleUpdateCategory}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleCloseCategoryDialog}
                >
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}{' '}
        </Dialog>
      )}

      {selectedServiceRow && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={openDialog}
          onClose={handleCloseServiceDialog}
        >
          <DialogTitle sx={{ pt: 2 }}>Delete</DialogTitle>
          {serviceFormErr && (
            <Typography sx={{ ml: 3, mb: 2 }} type="body2" color="error">
              {serviceFormErr}
            </Typography>
          )}
          <DialogContent sx={{ typography: 'body2' }}>
            Are you sure want to delete service {selectedServiceRow.name} ?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteService()}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseServiceDialog}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
