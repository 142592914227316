import axios from '../utils/Axios';

export const setSession = (accessToken, user) => {
  if (accessToken) {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('user', user);
    axios.defaults.headers.common['api-token'] = `${accessToken}`;
    axios.defaults.headers.common['api-user'] = `${user}`;
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['api-token'];
    delete axios.defaults.headers.common['api-user'];
  }
};
