import {
  Drawer,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  Container,
  Card,
  IconButton,
  Box,
  Stack,
  Tooltip,
  Divider,
} from '@mui/material';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

export default function CategoriesModal({
  openModal,
  onCloseModal,
  categories,
  onOpenCategoryDialog,
}) {
  return (
    <Drawer
      transitionDuration={150}
      anchor="right"
      open={openModal}
      onClose={onCloseModal}
      PaperProps={{
        sx: {
          width: {
            xs: 1,
            sm: 480,
          },
        },
      }}
    >
      <Stack pt={3} pb={3} direction="row" alignItems="center">
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          flexGrow={1}
          pr={2}
        >
          <Tooltip title="Close">
            <IconButton onClick={onCloseModal}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Divider />
      <Container>
        <Card>
          <Typography variant="h5" sx={{ mb: 3, mt: 3 }}>
            Categories
          </Typography>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="name" align="left">
                      Name
                    </TableCell>
                    <TableCell key="controls" align="right">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                          cursor: 'pointer',
                        }}
                        onClick={() => onOpenCategoryDialog(null, 'add')}
                      >
                        Add <Iconify icon="eva:plus-fill" />{' '}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {categories.length ? (
                  <TableBody>
                    {categories.map((row) => {
                      const { _id, name } = row;
                      return (
                        <TableRow hover key={_id}>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="medium"
                              color="inherit"
                              sx={{ fontSize: 15 }}
                              onClick={() => onOpenCategoryDialog(row, 'edit')}
                            >
                              <Iconify icon="eva:edit-fill" />
                            </IconButton>
                            <IconButton
                              size="medium"
                              sx={{ color: 'error.main', fontSize: 15 }}
                              onClick={() =>
                                onOpenCategoryDialog(row, 'delete')
                              }
                            >
                              <Iconify icon="eva:trash-2-outline" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  ''
                )}
                {!categories.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Categories
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Drawer>
  );
}
