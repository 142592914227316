import { useMemo, useEffect, useCallback, useState } from 'react';
import { sentenceCase } from 'change-case';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Drawer,
  TextField,
  Button,
  Stack,
  Box,
  Tooltip,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from '../../../components/hook-form';
import Scrollbar from '../../../components/scrollbar';
import { BOOKING_STATUS_COLORS } from '../../../theme/palette';
import Label from '../../../components/label';
import {
  getNow,
  endOfMonth,
  formatBookingDateForm,
  formatBookingTime,
  formatSlots,
} from '../../../utils/formatTime';
import axios from '../../../utils/Axios';

let currentFetchedDate = '';
let selectedService = null;

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  fontSize: 14,
  lineHeight: 2.5,
}));

export default function BookingModal({
  booking,
  openModal,
  onCloseModal,
  services,
  customers,
  isNew,
  createBooking,
  timezone,
}) {
  const [cal, setCal] = useState(null);
  const defaultValues = useMemo(
    () => ({
      customer: booking?.customer?._id || '',
      service: booking?.service?._id || '',
      startDate: booking?.startDate || '',
      internalNote: booking?.internalNote || '',
      additionalData: booking?.additionalData || '',
    }),
    [booking]
  );

  const methods = useForm({ defaultValues });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const [selectedDate, setSelectedDate] = useState(getNow());

  const onCalendarOpen = useCallback(async () => {
    const service = methods.getValues('service');
    console.log(services);
    if (!service) return;

    if (!selectedDate || selectedDate == null) {
      return;
    }

    const now = selectedDate.startOf('month');
    const startDate = now.format('YYYY-MM-DD');
    if (currentFetchedDate === startDate && service === selectedService) return;

    currentFetchedDate = startDate;
    selectedService = service;
    const endDate = `${now.get('year')}-${now.get('month') + 1}-${endOfMonth(
      now
    )}`;

    const calendar = await axios.get(
      `/services/${service}/calendar?startDate=${startDate}&endDate=${endDate}`
    );
    setCal(calendar.data);
  }, [setCal, selectedDate]);

  const onMonthChange = useCallback(
    async (date) => {
      const service = methods.getValues('service');
      if (!service) return;

      const startDate = date.format('YYYY-MM-DD');
      currentFetchedDate = startDate;
      selectedService = service;
      const endDate = `${date.get('year')}-${
        date.get('month') + 1
      }-${endOfMonth(date)}`;

      const calendar = await axios.get(
        `/services/${service}/calendar?startDate=${startDate}&endDate=${endDate}`
      );
      setCal(calendar.data);
    },
    [setCal]
  );

  const shouldDisableDate = useCallback(
    (date) => {
      if (!cal) return true;
      const formatedDate = date.format('YYYY-MM-DD');
      return cal[formatedDate] ? !cal[formatedDate].length : true;
    },
    [cal]
  );

  useEffect(() => {
    reset(defaultValues);
    return () => {
      currentFetchedDate = '';
      selectedService = null;
    };
  }, [booking, !isNew]);

  const onSubmit = async (data) => {
    try {
      await createBooking(data);
      onCloseModal();
      setCal([]);
      reset();
    } catch (error) {
      console.log(data);
    }
  };

  const maybeCurrentTimeSlots = () => {
    if (!cal || !selectedDate) return [];
    return cal[selectedDate.format('YYYY-MM-DD')] || [];
  };

  return (
    <Drawer
      anchor="right"
      open={openModal}
      onClose={onCloseModal}
      PaperProps={{
        sx: {
          width: {
            xs: 1,
            sm: 480,
          },
        },
      }}
    >
      <ToolBar>
        {!isNew && booking?.status ? (
          <Label
            sx={{
              ml: 2,
              height: 'auto',
              color: BOOKING_STATUS_COLORS[booking.status].color,
              background: BOOKING_STATUS_COLORS[booking.status].background,
              textAlign: 'center',
            }}
          >
            {sentenceCase(booking.status)}
          </Label>
        ) : (
          ''
        )}
        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          <Tooltip title="Close">
            <IconButton onClick={onCloseModal}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Tooltip>
        </Stack>
      </ToolBar>
      <Divider />
      <Stack sx={{ p: 2 }}>
        <Typography variant="h5">{isNew ? 'New' : 'View'} Booking</Typography>
      </Stack>
      <Scrollbar>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ p: 2 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Stack>
                <StyledLabel>Service:</StyledLabel>
                <RHFSelect native name="service" size="small" disabled={!isNew}>
                  <option value="">Select Service</option>
                  {services.map((service, index) => (
                    <option key={index} value={service._id}>
                      {service.name}
                    </option>
                  ))}
                </RHFSelect>
              </Stack>
              <Stack>
                <StyledLabel>Customer Name:</StyledLabel>
                <RHFSelect
                  native
                  name="customer"
                  size="small"
                  disabled={!isNew}
                >
                  <option value="">Select Customer</option>
                  {customers.map((customer, index) => (
                    <option key={index} value={customer._id}>
                      {customer.firstName} {customer.lastName}
                    </option>
                  ))}
                </RHFSelect>
              </Stack>
              <Stack>
                <StyledLabel>Date:</StyledLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {isNew ? (
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: 'small',
                          helperText:
                            'Click on the calendar icon to pick a date',
                        },
                      }}
                      onOpen={onCalendarOpen}
                      onMonthChange={onMonthChange}
                      views={['month', 'day']}
                      shouldDisableDate={shouldDisableDate}
                      onChange={(newDate) => setSelectedDate(newDate)}
                    />
                  ) : (
                    <TextField
                      size="small"
                      value={
                        booking?.startDate
                          ? formatBookingDateForm(booking.startDate, timezone)
                          : ''
                      }
                      disabled
                    />
                  )}
                </LocalizationProvider>
              </Stack>
              <Stack>
                <StyledLabel>Time:</StyledLabel>
                {isNew ? (
                  <RHFSelect
                    native
                    name="startDate"
                    size="small"
                    disabled={!cal}
                  >
                    <option value="">Select Time:</option>
                    {maybeCurrentTimeSlots().map((time, index) => (
                      <option key={index} value={time}>
                        {formatSlots(time)}
                      </option>
                    ))}
                  </RHFSelect>
                ) : (
                  <TextField
                    size="small"
                    value={
                      booking?.startDate
                        ? formatBookingTime(booking.startDate, timezone)
                        : ''
                    }
                    disabled
                  />
                )}
              </Stack>
            </Box>
            <Stack sx={{ mt: 3, mb: 1 }}>
              <StyledLabel>Additional Data:</StyledLabel>
              <RHFTextField
                disabled={!isNew}
                multiline
                name="additionalData"
                rows={4}
                size="small"
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            flexGrow={1}
            mr={2}
          >
            <Button
              onClick={onCloseModal}
              size="medium"
              color="inherit"
              variant="outlined"
            >
              cancel
            </Button>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Scrollbar>
    </Drawer>
  );
}

function ToolBar({ children }) {
  return (
    <Stack pt={3} pb={3} direction="row" alignItems="center">
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        flexGrow={1}
        pr={2}
      >
        {children}
      </Stack>
    </Stack>
  );
}
