const products = [];

for (let i = 1; i < 16; i += 1) {
  if (i === 2) {
    for (let j = 1; j <= 3; j += 1) {
      products.push({
        _id: `${i}-${j}`,
        cloneableHasThumbnailService: false,
        form: `https://res.cloudinary.com/de7fg4j42/raw/upload/v1681662743/cloneable/forms/cloneable_${i}-${j}.json`,
        cover: `/assets/images/products/Cloneable_${i}-${j}.png`,
        name: `Cloneable ${i}.${j}`,
        previewLink: `https://flow-bookings-cloneables.webflow.io/cloneables/${i}-${j}`,
      });
    }
  } else {
    products.push({
      _id: `${i}`,
      cloneableHasThumbnailService: i === 4 || i === 13,
      form: `https://res.cloudinary.com/de7fg4j42/raw/upload/v1681662743/cloneable/forms/cloneable-${i}.json`,
      cover: `/assets/images/products/Cloneable_${i}.png`,
      name: `Cloneable ${i}`,
      previewLink: `https://flow-bookings-cloneables.webflow.io/cloneables/${i}`,
    });
  }
}

export default products;
