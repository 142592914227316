import { Helmet } from 'react-helmet-async';
import { filter, findIndex } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import {
  Card,
  Alert,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,

  // Dialog stuff
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { TableSkeleton } from '../components/skeleton';
import axios from '../utils/Axios';
import CustomersModal from '../sections/@dashboard/customers/Modal';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import {
  TableListHead,
  TableListToolbar,
} from '../sections/@dashboard/customers';
import { formatBookingDate } from '../utils/formatTime';
import { useAuthContext } from '../auth/useAuthContext';

const TABLE_HEAD = [
  { id: 'firstName', label: 'Customer', alignRight: false },
  { id: 'lastBookingDate', label: 'Last Appointment', alignRight: false },
  { id: 'phoneNumber', label: 'Phone', alignRight: false },
  { id: 'totalBookings', label: 'Total Bookings', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_customer) =>
        _customer.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomersPage() {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopover, setOpenPopover] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('lastBookingDate');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [success, setSuccess] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const {
    user: { config, _id },
  } = useAuthContext();

  const handleOpenModal = (row) => {
    setOpenPopover(null);
    setOpenModal(true);
    setSelectedRow(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (openDialog) {
      setOpenDialog(false);
    }

    if (selectedRow != null) {
      setSelectedRow(null);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenMenu = (event, rowId) => {
    setOpenPopover(event.currentTarget);
    setSelectedRow({ _id: rowId });
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteCustomer = async () => {
    try {
      await removeCustomer();
      handleCloseModal();
      setDeleteError(null);
    } catch (error) {
      setDeleteError(error.messages?.join('') || 'Try again later');
    }
  };

  const getCustomers = useCallback(async () => {
    try {
      const response = await axios.get('/customers');
      setCustomers(response.data.customers);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const createCustomer = useCallback(
    async (customerData) => {
      const response = await axios.post('/customers', customerData);
      setCustomers([...customers, response.data.customer]);
      setSuccess({
        bg: 'success',
        message: 'Customer Created.',
      });
      setTimeout(() => setSuccess(null), 6000);
    },
    [customers, setCustomers]
  );

  const updateCustomer = useCallback(
    async (customerData) => {
      if (!selectedRow && !selectedRow._id)
        throw new Error('Customer is not selected');
      const customerId = selectedRow._id;
      const response = await axios.put(
        `/customers/${customerId}`,
        customerData
      );
      const mutatedCustomers = [...customers];
      const customerIndex = findIndex(mutatedCustomers, { _id: customerId });
      mutatedCustomers.splice(customerIndex, 1, response.data.customer);
      setCustomers(mutatedCustomers);
    },
    [customers, setCustomers, selectedRow]
  );

  const removeCustomer = useCallback(async () => {
    if (!selectedRow && !selectedRow._id)
      throw new Error('Customer is not selected');
    const customerId = selectedRow._id;
    const response = await axios.delete(`/customers/${selectedRow._id}`);
    if (response.status === 200) {
      const mutatedCustomers = [...customers];
      const customerIndex = findIndex(mutatedCustomers, { _id: customerId });
      mutatedCustomers.splice(customerIndex, 1);
      setCustomers(mutatedCustomers);
    }
  }, [selectedRow, customers, setCustomers]);

  useEffect(() => {
    getCustomers();
  }, []);

  const filteredCustomers = applySortFilter(
    customers,
    getComparator(order, orderBy),
    filterName
  );

  return (
    <>
      <Helmet>
        <title>Customers | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
          {success != null && (
            <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
              {success.message}
            </Alert>
          )}
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={(event) => {
              setIsEdit(false);
              handleOpenModal(event, null);
            }}
          >
            New Customer
          </Button>
        </Stack>

        <Card>
          <TableListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={customers.length}
                  onRequestSort={handleRequestSort}
                />
                {isLoading && [...Array(5)].map(() => <TableSkeleton />)}
                {!isLoading && customers.length ? (
                  <TableBody>
                    {filteredCustomers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          firstName,
                          lastName,
                          email,
                          _id,
                          lastBookingDate,
                          phoneNumber,
                          totalBookings,
                        } = row;
                        return (
                          <TableRow hover key={_id}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ width: 500 }}
                            >
                              <Stack
                                direction="column"
                                alignItems="left"
                                spacing={0.5}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {firstName} {lastName}
                                </Typography>
                                <Typography variant="subtitle3">
                                  {email}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {lastBookingDate
                                ? formatBookingDate(
                                  lastBookingDate,
                                  config.timezone
                                )
                                : 'No Appointments Made'}
                            </TableCell>
                            <TableCell align="left">{phoneNumber}</TableCell>
                            <TableCell align="left">{totalBookings}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, _id)}
                              >
                                <Iconify icon="eva:more-vertical-fill" />
                              </IconButton>
                            </TableCell>
                            <Popover
                              open={Boolean(
                                openPopover &&
                                selectedRow &&
                                selectedRow._id === _id
                              )}
                              anchorEl={openPopover}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 140,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleOpenModal(row);
                                  setIsEdit(true);
                                }}
                              >
                                <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>

                              <MenuItem
                                sx={{ color: 'error.main' }}
                                onClick={() => {
                                  setSelectedRow(row);
                                  handleOpenDialog();
                                }}
                              >
                                <Iconify
                                  icon="eva:trash-2-outline"
                                  sx={{ mr: 2 }}
                                />
                                Delete
                              </MenuItem>
                            </Popover>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  ''
                )}
                {!isLoading && !filteredCustomers.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Customers
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredCustomers.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <CustomersModal
        customer={selectedRow}
        isEdit={isEdit}
        createCustomer={createCustomer}
        updateCustomer={updateCustomer}
        onOpenDialog={handleOpenDialog}
        openModal={openModal}
        onCloseModal={handleCloseModal}
      />

      {selectedRow && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle sx={{ pb: 2 }}>Delete</DialogTitle>

          <DialogContent sx={{ typography: 'body2' }}>
            Are you sure want to delete {selectedRow.firstName}{' '}
            {selectedRow.lastName} ?
          </DialogContent>
          {deleteError && (
            <Typography sx={{ pl: 2 }} type="body2" color="error">
              {deleteError}
            </Typography>
          )}
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteCustomer()}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
