import React, { useMemo, useEffect } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import { EventEmitter } from 'events';
import tinycolor from "tinycolor2";

export const themeEventEmitter = new EventEmitter();

export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(() => ({
    palette,
    shape: { borderRadius: 6 },
    typography,
    shadows: shadows(),
    customShadows: customShadows(),
  }),
    []
  );
  const [loadCount, setLoadCount] = React.useState(0);

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  useEffect(() => {
    const updateTheme = () => {

      console.log('updating Theme');

      const primaryColor = localStorage.getItem('primaryColor');
      const accentColor = localStorage.getItem('accentColor');

      const palette = { ...theme.palette }

      console.log('palette', palette);
      console.log('palette.primary.main', palette.primary.main);
      console.log('primaryColor.toLowerCase()', primaryColor.toLowerCase());

      if (primaryColor && palette.primary.main !== primaryColor.toLowerCase()) {
        palette.primary.main = primaryColor.toLowerCase();
        palette.primary.lighter = tinycolor(primaryColor).lighten(50).toString();
        palette.primary.light = tinycolor(primaryColor).lighten(20).toString();
        palette.primary.dark = tinycolor(primaryColor).darken(20).toString();
        palette.primary.darker = tinycolor(primaryColor).darken(50).toString();

        palette.secondary.main = accentColor.toLowerCase();
        palette.secondary.lighter = tinycolor(accentColor).lighten(50).toString();
        palette.secondary.light = tinycolor(accentColor).lighten(20).toString();
        palette.secondary.dark = tinycolor(accentColor).darken(20).toString();
        palette.secondary.darker = tinycolor(accentColor).darken(50).toString();

        theme.palette = { ...palette };

        const updatedTheme = createTheme({ ...theme });
        updatedTheme.components = componentsOverride(updatedTheme);

        window.location.reload();
      }
    };

    themeEventEmitter.on('updateTheme', updateTheme);

    return () => {
      themeEventEmitter.off('updateTheme', updateTheme);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
