import { useAuthContext } from './useAuthContext';

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  if (!isInitialized) {
    return 'loading ....';
  }

  if (!isAuthenticated) {
    window.location = '/login';
    return null;
  }

  return children;
}
