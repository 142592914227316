import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

export default function ProductList({ products, setProduct, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={4} md={3}>
          <ShopProductCard
            product={product}
            setProduct={setProduct}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
