import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Alert, Grid, Box, Card, CardContent, Avatar, Button, TextField } from '@mui/material';
import Profile from '../sections/@dashboard/account/Profile';
import { useAuthContext } from '../auth/useAuthContext';
import axios from '../utils/Axios';
import { useSearchParams } from 'react-router-dom';

export default function AgencyStripe() {
  const { user, setGlobalUser } = useAuthContext();
  const [searchParams] = useSearchParams();

  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [accountConnected, setAccountConnected] = useState(user.stripeAccountStatus == "ONBOARDED" ? true : searchParams.get('successAccountId'));

  const sendLink = async () => {
    try {
      const response = await axios.post(`/stripe/connect-link`, {});
      const link = response.data.link;
      window.location.href = link;
    } catch (error) {
      setFormErrors({ message: error.message });
      setTimeout(() => setFormErrors(null), 6000);
    }
  };

  const checkStripeAccount = async () => {
    if (!searchParams.get('successAccountId')) return;
    try {
      await axios.post(`/stripe/update-account-status`, {
        accountId: searchParams.get('successAccountId')
      });
    } catch (error) {
      setFormErrors({ message: error.message });
      setTimeout(() => setFormErrors(null), 6000);
    }
  };

  useEffect(() => {
    checkStripeAccount();
  }, []);

  return (
    <>
      <Helmet>
        <title>Agency Branding Setup | FlowBookings</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Agency Branding Setup
        </Typography>
        {!!formErrors && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {formErrors.message}
          </Alert>
        )}
        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}

        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Connect Stripe
            </Typography>
            <Typography variant="body2" gutterBottom>
              Connect your Stripe account to collect payments from your clients. The products/plans on stripe will stay exactly same as on Flowbookings.io
            </Typography>

            <Box sx={{ mt: 3 }}>
              {!accountConnected && <Button
                color="primary"
                variant="contained"
                onClick={() => sendLink()}
              >
                Connect Stripe
              </Button>}

              {accountConnected && <Button
                color="primary"
                variant="contained"
                disabled
              >
                Stripe Connected
              </Button>}
            </Box>
          </CardContent>
        </Card>

      </Container>
    </>
  );
}
