import { useMemo, useState, useEffect } from 'react';
import {
  Button,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  InputLabel,
  Typography,
  Box,
  Card,
  FormControl,
  Alert,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/iconify';
import { EmailSkeleton } from '../../../components/skeleton';

const TABS = [
  {
    value: 'customer',
    label: 'To Customer',
    icon: <Iconify icon="ic:group" />,
  },
  {
    value: 'admin',
    label: 'To Admin',
    icon: <Iconify icon="ic:person" />,
  },
];

const SUBJECT = [
  { name: 'approved', label: 'Booking Approved' },
  { name: 'pending', label: 'Booking Pending' },
  { name: 'canceled', label: 'Booking Canceled' },
  { name: 'rejected', label: 'Booking Rejected' },
  { name: 'reminder', label: 'Booking Reminder' },
];

const ADMIN_SUBJECT = [
  { name: 'admin_pending', label: 'New Booking' },
  { name: 'admin_reminder', label: 'Booking Reminder' },
];

export default function EmailForm({ emails, updateEmails }) {
  const [currentTab, setCurrentTab] = useState(0);
  const [activeSubject, setActiveSubject] = useState('approved');

  const [isCopied, setIsCopied] = useState(false);

  const defaultValues = useMemo(() => emails, [emails]);
  const methods = useForm({ defaultValues });
  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const [isCheckedForm, setIsCheckedForm] = useState(false);

  useEffect(() => {
    reset(defaultValues);
    if (defaultValues.pending) {
      setIsCheckedForm(defaultValues[activeSubject].isNotify);
    }
  }, [activeSubject, emails]);

  useMemo(() => {
    if (currentTab === 0) {
      setActiveSubject(SUBJECT[0].name);
    } else {
      setActiveSubject(ADMIN_SUBJECT[0].name);
    }
  }, [currentTab]);

  const onSubmit = async (data) => {
    try {
      await updateEmails(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      display="grid"
      columnGap={3}
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(1, 25%) 1fr',
      }}
    >
      <Card sx={{ p: 3, height: '100%' }}>
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
        >
          {TABS.map((tab) => (
            <Tab
              className="w-fit"
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
            />
          ))}
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
            }}
          >
            {SUBJECT.map((sub) => (
              <Button
                onClick={() => setActiveSubject(sub.name)}
                color={sub.name === activeSubject ? 'primary' : 'inherit'}
                size="large"
                sx={{ mt: 2 }}
                variant="outlined"
              >
                {sub.label}
              </Button>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
            }}
          >
            {ADMIN_SUBJECT.map((sub) => (
              <Button
                onClick={() => setActiveSubject(sub.name)}
                color={sub.name === activeSubject ? 'primary' : 'inherit'}
                size="large"
                sx={{ mt: 2 }}
                variant="outlined"
              >
                {sub.label}
              </Button>
            ))}
          </Box>
        </TabPanel>
      </Card>
      <Card sx={{ p: 3 }}>
        {!emails.pending && <EmailSkeleton />}
        {emails.pending && (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  onChange={(_, val) => {
                    setValue(`${activeSubject}[isNotify]`, val, {
                      shouldValidate: true,
                    });
                    setIsCheckedForm(val);
                  }}
                  checked={isCheckedForm}
                />
              }
              label="Send Notification"
            />
            <Box className="flex flex-col h-full justify-start mb-4">
              <Typography
                sx={{
                  opacity: '0.5',
                  fontSize: '14px',
                  my: 2,
                  fontWeight: '700',
                }}
              >
                Subject:
              </Typography>
              <RHFTextField
                name={`${activeSubject}[subject]`}
                placeholder={`Booking ${activeSubject}`}
                InputProps={{
                  sx: { typography: 'body2' },
                }}
              />
              <Typography
                sx={{
                  opacity: '0.5',
                  fontSize: '14px',
                  my: 2,
                  fontWeight: '700',
                }}
              >
                Content:
              </Typography>
              <RHFTextField
                fullWidth
                multiline
                name={`${activeSubject}[message]`}
                rows={5}
                placeholder="Enter your email content here"
              />
              <Typography
                sx={{
                  opacity: '0.5',
                  fontSize: '14px',
                  my: 2,
                  fontWeight: '700',
                }}
              >
                Insert Placeholder:
              </Typography>
              {isCopied && (
                <Alert severity="success" color="info" sx={{ mb: 3 }}>
                  Copied to clipboard
                </Alert>
              )}
              <FormControl sx={{ minWidth: 150, mb: 3, mr: 3 }}>
                <InputLabel>Admin</InputLabel>
                <Select label="Admin">
                  {['%admin_first_name%'].map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={() => {
                        navigator.clipboard.writeText(option);
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 1000);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 150, mb: 3, mr: 3 }}>
                <InputLabel>Customer</InputLabel>
                <Select label="Customer">
                  {['%customer_full_name%'].map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={() => {
                        navigator.clipboard.writeText(option);
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 1000);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 150, mb: 3, mr: 3 }}>
                <InputLabel>Booking</InputLabel>
                <Select label="Booking">
                  {['%booking_date%', '%booking_time%', '%service_name%'].map(
                    (option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        onClick={() => {
                          navigator.clipboard.writeText(option);
                          setIsCopied(true);
                          setTimeout(() => setIsCopied(false), 1000);
                        }}
                      >
                        {option}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 150, mb: 3 }}>
                <InputLabel>Company</InputLabel>
                <Select label="Company">
                  {['%company_name%'].map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      onClick={() => {
                        navigator.clipboard.writeText(option);
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 1000);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              display="flex"
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              flexGrow={1}
              mr={2}
            >
              <LoadingButton
                type="submit"
                size="medium"
                variant="contained"
                loading={isSubmitting}
              >
                Save Changes
              </LoadingButton>
            </Box>
          </FormProvider>
        )}
      </Card>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}> {children}</Box>}
    </div>
  );
}
