import { alpha } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';

export default function RejectionFiles({ fileRejections }) {
  if (!fileRejections.length) {
    return null;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
      }}
    >
      {fileRejections.map(({ _, errors }) => (
        <Box sx={{ my: 1 }}>
          {errors.map((error) => (
            <Box
              key={error.code}
              component="span"
              sx={{ typography: 'caption' }}
            >
              - {error.message}
            </Box>
          ))}
        </Box>
      ))}
    </Paper>
  );
}
