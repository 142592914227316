import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: (window.location.hostname === "localhost") ? 'https://beta-api.flowbookings.io' : 'https://beta-api.flowbookings.io',
  //baseURL: process.env.REACT_APP_SERVER_URL || 'https://api.flowbookings.io',
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const shouldLogoutUser =
      error.response.data.error === 'expired_token' ||
      error.response.data.error === 'invalid_token';
    if (shouldLogoutUser) {
      window.localStorage.removeItem('token'); 
      window.localStorage.removeItem('user');
      window.location = '/login';
    } 

    return Promise.reject(
      (error.response && error.response.data) ||
      'Something went wrong please try again.'
    );
  }
);

export default axiosInstance;
