import { styled, alpha } from '@mui/material/styles';
import { useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  IconButton,
  Popover,
  AppBar,
  Toolbar,
  Avatar,
  Alert,
  Container,
  Button,
} from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import Iconify from '../../../components/iconify';
import { useAuthContext } from '../../../auth/useAuthContext';
import { isExpiredSubscription } from '../../../utils/formatTime';
import axios from '../../../utils/Axios';

const NAV_WIDTH = 280;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

function AccountPopover() {
  const { user, logout } = useAuthContext();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={user.avatar} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

export function GlobalAlerts() {
  const { user } = useAuthContext();
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const handleResendEmail = async () => {
    try {
      await axios.post('/user/resend-verification');
      setIsVerificationSent(true);
    } catch (err) {
      console.log(err);
    }
  };
  const isExpiredSub =
    user.subscription.subscriptionId &&
    isExpiredSubscription(user.subscription.dateExpiry);
  const isUnverifiedAccount = user.verificationTokenExpiry != null;
  return (
    <Container maxWidth="xl">
      {isExpiredSub && window.location.hostname != "agency.flowbookings.io" && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Your subscription has expired please choose billing from the menu to
          renew and continue using the application!
        </Alert>
      )}
      {isUnverifiedAccount && (
        <Alert severity="error" sx={{ alignItems: 'center', mb: 3 }}>
          Please verify your email address to ensure the security of your
          account. Didn't receive the verification email ?
          {!isVerificationSent && (
            <Button
              size="small"
              variant="error"
              sx={{ ml: 2 }}
              onClick={handleResendEmail}
            >
              Click here to Resend
            </Button>
          )}
        </Alert>
      )}
    </Container>
  );
}

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <AccountPopover />
      </StyledToolbar>
    </StyledRoot>
  );
}
