import Router from './routes';
import ThemeProvider, { themeEventEmitter } from './theme';
import ScrollToTop from './components/scroll-to-top';
import { AuthProvider } from './auth/AuthContext';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

export default function App() {
  const [themeLoaded, setThemeLoaded] = useState(false);

  const getTheme = useCallback(async () => {
    try {

      let domain = "https://beta-api.flowbookings.io"

      // if (window.location.hostname === "localhost") {
      //   domain = "http://localhost:8084"
      // } 

      const themeResponse = await axios.get(domain + '/agency/get-theme');
      const theme = themeResponse.data.theme;

      if (theme && theme.primaryColor && theme.accentColor) {
        localStorage.setItem('primaryColor', theme.primaryColor);
        localStorage.setItem('accentColor', theme.accentColor);
        localStorage.setItem('logo', theme.logo);
        localStorage.setItem('companyName', theme.companyName);

        themeEventEmitter.emit('updateTheme');
      }

      setThemeLoaded(true);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTheme();
  }, []);


  return (<>
    {themeLoaded && <AuthProvider>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </AuthProvider>}
  </>);
}
