import { Box, Card, Typography } from '@mui/material';
import Iconify from '../../../components/iconify';

export default function AppWidgetSummary({
  title,
  total,
  color,
  sx,
  ...other
}) {
  return (
    <Card
      sx={{ display: 'flex', alignItems: 'center', p: 3, ...sx }}
      {...other}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="h3">{total}</Typography>
      </Box>

      <Iconify
        icon="eva:bar-chart-2-outline"
        sx={{
          mr: 1,
          p: 0.5,
          width: 50,
          height: 50,
          color,
        }}
      />
    </Card>
  );
}
