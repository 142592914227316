import { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Button,
  Tooltip,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import FormProvider, { RHFTextField } from '../../../components/hook-form';

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  fontSize: 14,
  lineHeight: 2.5,
}));

export default function CustomersModal({
  customer,
  isEdit,
  createCustomer,
  updateCustomer,
  openModal,
  onCloseModal,
  onOpenDialog,
}) {
  const defaultValues = useMemo(
    () => ({
      firstName: customer?.firstName || '',
      lastName: customer?.lastName || '',
      email: customer?.email || '',
      phoneNumber: customer?.phoneNumber || '',
      note: customer?.note || '',
    }),
    [customer]
  );

  const methods = useForm({ defaultValues });
  const [formErrors, setFormErrors] = useState(null);
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [isEdit, customer]);

  const onSubmit = async (data) => {
    try {
      if (formErrors) {
        setFormErrors(null);
      }

      if (isEdit) {
        await updateCustomer(data);
      } else {
        await createCustomer(data);
      }

      reset();
      onCloseModal();
    } catch (error) {
      setFormErrors({ message: error.messages.join('\n') });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openModal}
      onClose={onCloseModal}
      PaperProps={{
        sx: {
          width: {
            xs: 1,
            sm: 480,
          },
        },
      }}
    >
      {!!formErrors && <Alert severity="error">{formErrors.message}</Alert>}

      <ToolBar>
        {isEdit && (
          <Tooltip title="Delete">
            <IconButton size="small" onClick={onOpenDialog}>
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Close">
          <IconButton onClick={onCloseModal}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Tooltip>
      </ToolBar>

      <Divider />

      <Stack sx={{ p: 2 }}>
        <Typography variant="h5">
          {!isEdit ? 'Create' : 'Update'} Customer
        </Typography>
      </Stack>

      <Scrollbar>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ p: 2 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Stack>
                <StyledLabel>First Name:</StyledLabel>
                <RHFTextField name="firstName" rows={4} size="small" />
              </Stack>

              <Stack>
                <StyledLabel>Last Name:</StyledLabel>
                <RHFTextField name="lastName" rows={4} size="small" />
              </Stack>
              <Stack>
                <StyledLabel>Email:</StyledLabel>
                <RHFTextField name="email" rows={4} size="small" />
              </Stack>
              <Stack>
                <StyledLabel>Phone:</StyledLabel>
                <RHFTextField name="phoneNumber" rows={4} size="small" />
              </Stack>
            </Box>
            <Stack sx={{ mt: 3, mb: 3 }}>
              <StyledLabel>Note:</StyledLabel>
              <RHFTextField multiline name="note" rows={4} size="small" />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            flexGrow={1}
            mr={2}
          >
            <Button
              size="medium"
              color="inherit"
              variant="outlined"
              onClick={onCloseModal}
            >
              cancel
            </Button>
            <LoadingButton
              type="submit"
              size="medium"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Scrollbar>
    </Drawer>
  );
}

function ToolBar({ children }) {
  return (
    <Stack pt={3} pb={3} direction="row" alignItems="center">
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        flexGrow={1}
        pr={2}
      >
        {children}
      </Stack>
    </Stack>
  );
}
