import { useState, useRef, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Button,
  Box,
  Divider,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { NavLink as RouterLink } from 'react-router-dom';
import Slider from 'react-slick';
import ReCAPTCHA from 'react-google-recaptcha';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import axios from '../utils/Axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FormProvider, { RHFTextField } from '../components/hook-form';
import { useAuthContext } from '../auth/useAuthContext';
import { themeEventEmitter } from 'src/theme';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(() => ({
  width: '50%',
  padding: 50,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: localStorage.getItem('primaryColor') || '#46C492',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const urlSearchParams = new URLSearchParams(window.location.search);
const price = urlSearchParams.get('price') || '';

export default function RegisterPage() {
  const { signUpUser } = useAuthContext();
  const settings = {
    dots: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const methods = useForm({
    defaultValues: { email: '', password: '', firstName: '', lastName: '' },
  });
  const recaptchaRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [errorForm, setErrorForm] = useState(null);

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const mdUp = useResponsive('up', 'sm');

  const handleSignUpWithGoogle = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const price = urlSearchParams.get('price') || '';

    const response = await axios.get(`/auth/google/url?price=${price}`);
    if (response.data) {
      window.location = response.data.url;
    }
  };

  const onSubmit = async (data) => {
    try {
      let captcha = null;

      if (recaptchaRef.current) {
        captcha = await recaptchaRef.current.executeAsync();
      }

      await signUpUser({ ...data, captcha });
      setTimeout(() => {
        window.location = `/verify?state=${price}`;
      }, 3000);
    } catch (error) {
      console.log(error);
      reset();
      setErrorForm({ message: error.messages.join('\n') });
    }
  };


  return (
    <>
      <Helmet>
        <title> Register | {localStorage.getItem("companyName") ?? "FlowBookings"} </title>
      </Helmet>

      <StyledRoot>
        <Logo
          width="160px"
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container>
          <StyledContent>
            <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
              Welcome to {localStorage.getItem("companyName") ?? "FlowBookings"}!
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', textAlign: 'center', mb: 2 }}
            >
              Enter your details to get started.
            </Typography>

            {window.location.hostname.includes("app.flowbookings.io") && <Button
              fullWidth
              size="large"
              color="inherit"
              variant="contained"
              sx={{
                backgroundColor: '#4285f4',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#4285f4',
                },
              }}
              onClick={handleSignUpWithGoogle}
            >
              <Iconify
                icon="eva:google-fill"
                color="#fff"
                width={22}
                height={22}
              />
              <Typography sx={{ px: 2 }}>Sign up with Google</Typography>
            </Button>}
            {window.location.hostname.includes("app.flowbookings.io") && <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                or continue with email
              </Typography>
            </Divider>}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              {!!errorForm && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errorForm.message}
                </Alert>
              )}
              <RHFTextField
                name="firstName"
                size="large"
                placeholder="First Name"
                InputProps={{
                  sx: { mb: 3 },
                }}
              />
              <RHFTextField
                name="lastName"
                size="large"
                placeholder="Last Name"
                InputProps={{
                  sx: { mb: 3 },
                }}
              />
              <RHFTextField
                name="email"
                size="large"
                placeholder="Email"
                InputProps={{
                  sx: { mb: 3 },
                }}
              />
              <RHFTextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                size="large"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {window.location.hostname.includes("app.flowbookings.io") && <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdJD_olAAAAAIuRxejs1qI2PX4QUftTya_o6MIH"
              />}
              <LoadingButton
                sx={{ mt: 2, backgroundColor: localStorage.getItem("primaryColor") ?? '#36B37E' }}
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                loading={isSubmitting}
              >
                Sign Up
              </LoadingButton>
              <Stack
                direction="row"
                spacing={0.5}
                sx={{ justifyContent: 'center', mt: 2 }}
              >
                <Typography variant="body2">Already a user?</Typography>
                <Typography
                  component={RouterLink}
                  to={`/login?price=${price}`}
                  variant="body2"
                  sx={{ color: localStorage.getItem("primaryColor") ?? '#46C492', fontWeight: 'bold' }}
                >
                  Login here
                </Typography>
              </Stack>
            </FormProvider>
          </StyledContent>
        </Container>

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ mb: 3, color: '#fff' }}>
              The Ultimate Booking Solution <br /> for Webflow!
            </Typography>

            <Slider {...settings}>
              <Box
                component="img"
                src="/assets/images/products/Cloneable_7.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
              <Box
                component="img"
                src="/assets/images/products/Cloneable_10.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
              <Box
                component="img"
                src="/assets/images/products/Cloneable_12.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
              <Box
                component="img"
                src="/assets/images/products/Cloneable_14.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
            </Slider>
          </StyledSection>
        )}
      </StyledRoot>
    </>
  );
}
