import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Typography, Container, Button } from '@mui/material';
import axios from '../utils/Axios';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function VerifyEmailPage() {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const token = urlSearchParams.get('token');
      await axios.get(`auth/verify?token=${token}`);
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Verify Email | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Verifying Email
          </Typography>
          {isSuccess && (
            <>
              <Typography variant="h5" sx={{ color: 'green', mb: 2 }}>
                Email Verified
              </Typography>
              <Button
                onClick={() => {
                  window.location = '/dashboard/app';
                }}
                to="/dashboard/app"
                size="large"
                variant="contained"
              >
                Go to Dashboard
              </Button>
            </>
          )}
          {!isError && !isSuccess && (
            <Typography sx={{ color: 'red' }}>
              {' '}
              Please wait while we veriy your email.
            </Typography>
          )}
          {isError && !isSuccess && (
            <Typography sx={{ color: 'red' }}>
              Invalid verification link
            </Typography>
          )}
        </StyledContent>
      </Container>
    </>
  );
}
