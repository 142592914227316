import { findIndex } from 'lodash';

export function getValidConfig(
  config,
  { shouldIncludeTimezone, shouldIncludeStatus }
) {
  if (!config) {
    const defaultConfig = {
      beforeBuffer: 0,
      afterBuffer: 0,
      availability: getAvailability([]),
    };
    if (shouldIncludeTimezone) {
      defaultConfig.timezone = 'Europe/London';
    }
    if (shouldIncludeStatus) {
      defaultConfig.bookingStatus = 'PENDING';
    }
    return defaultConfig;
  }

  const { availability } = config;

  return {
    ...config,
    availability: getAvailability(availability),
  };
}

function getAvailability(availability) {
  const newAvailability = [];

  for (let i = 0; i < 7; i += 1) {
    const day = findIndex(availability, { day: i });

    if (day === -1) {
      newAvailability.push({
        day: i,
        startDate: 'off',
        endDate: 'off',
        breaks: [],
      });
    } else {
      newAvailability.push(availability[day]);
    }
  }

  return newAvailability.sort((a, b) => a.day > b.day);
}
