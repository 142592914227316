import { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Card, Stack, IconButton, Button, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import timezones from 'timezones-list';
import FormProvider, { RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import WorkingHours from '../../../components/working-hours';
import { getValidConfig } from '../../../utils/schedule';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Iconify from 'src/components/iconify';
import palette from 'src/theme/palette';
import dayjs from 'dayjs';

const BOOKING_STATUS_OPTIONS = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'No-Show', value: 'NO_SHOW' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Canceled', value: 'CANCELED' },
];

const BUFFER_OPTIONS = [
  { label: 'No Buffer', value: 0 },
  { label: '5 Mins', value: 5 },
  { label: '10 Mins', value: 10 },
  { label: '15 Mins', value: 15 },
  { label: '20 Mins', value: 20 },
  { label: '30 Mins', value: 30 },
  { label: '45 Mins', value: 45 },
  { label: '60 Mins', value: 60 },
];

const StyledLabel = styled('span')(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  fontSize: 14,
  lineHeight: 2.5,
}));

export default function PaymentSettingsForm({ config, updateUser }) {
  const [availability, setAvailability] = useState([]);
  const defaultValues = useMemo(() => ({
    config: {
      enablePayments: false,
      paymentMethod: 'inPerson',
      currency: 'USD',
      stripePublishableKey: null,
      stripeSecretKey: null,
      ...getValidConfig(config, { shouldIncludeTimezone: true })
    }
  }), [config]);

  const [holidays, setHolidays] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (defaultValues.config && defaultValues.config.availability) {
      setAvailability(defaultValues.config.availability);
    }
    if (defaultValues.config && defaultValues.config.holidays) {
      const savedHolidays = defaultValues.config.holidays.map((holiday) => {
        return dayjs(holiday);
      });
      setHolidays(savedHolidays);
    }
  }, [defaultValues]);

  const methods = useForm({ defaultValues: defaultValues });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    data.config.availability = availability.filter(
      (day) => day.startDate !== 'off' && day.endDate !== 'off'
    );
    data.config.holidays = holidays.filter((day) => day !== null);

    if (data.config.paymentMethod === 'stripe') {
      if (!data.config.stripePublishableKey || !data.config.stripeSecretKey) {
        setError({
          message: 'Please enter Stripe Publishable Key and Stripe Secret Key',
        });
        return;
      } else {
        setError(null);
      }
    } else {

      data.config.stripePublishableKey = null;
      data.config.stripeSecretKey = null;

      setError(null);
    }

    await updateUser(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

      <Typography variant="h6" sx={{ mb: 2 }} style={{ color: "#212B36", fontSize: 13, textTransform: 'uppercase' }}>
        Do You want to collect payments for your bookings?
      </Typography>

      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        flexGrow={1}
        mt={2}
        mb={2}
      >
        <RHFSwitch
          name='config[][enablePayments]'
          label=''
        />
        <div style={{ marginLeft: -15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ mb: 2 }} style={{ color: "#637381", fontSize: 13, marginBottom: 0 }}>
            Yes, Turn on payments.
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }} style={{ color: "#637381", fontSize: 13, fontWeight: 400, marginBottom: 0 }}>
            (This will enable a price field on your services, you can then choose Stripe or In-person payments)
          </Typography>
        </div>
      </Stack>

      {methods.watch('config[][enablePayments]') &&
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>

            <Card sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Configure Payment Method
              </Typography>

              <Typography sx={{ mb: 2 }} style={{ color: "#637381", fontSize: 13 }}>
                Choose a payment method to collect payments from your customers. You can choose to collect payments via Stripe or in-person.
              </Typography>

              <RHFSelect
                name='config[][paymentMethod]'
                label='Payment Method'
                native
                defaultValue={'inPerson'}
              >
                <option value={'stripe'}>Stripe</option>
                <option value={'inPerson'}>Collect Payments In-Person</option>
              </RHFSelect>

              {methods.watch('config[][paymentMethod]') === 'stripe' &&
                <div>
                  <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
                    Stripe Settings
                  </Typography>


                  {error != null && (
                    <Alert severity="error" color="info" sx={{ mt: 2, mb: 2 }}>
                      {error.message}
                    </Alert>
                  )}

                  <Typography sx={{ mb: 2 }} style={{ color: "#637381", fontSize: 13 }}>
                    Enter your Stripe Publishable Key and Stripe Client Key to enable Stripe Payments.
                  </Typography>

                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    flexGrow={1}
                    mt={2}
                    mb={2}
                  >
                    <div style={{
                      width: 200,
                    }}><StyledLabel>Publishable Key</StyledLabel></div>

                    <RHFTextField
                      name='config[][stripePublishableKey]'
                      placeholder='Enter Stripe Publishable Key'
                    />

                  </Stack>

                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    flexGrow={1}
                    mt={2}
                    mb={2}
                  >
                    <div style={{
                      width: 200,
                    }}><StyledLabel>Secret Key</StyledLabel></div>

                    <RHFTextField
                      name='config[][stripeSecretKey]'
                      placeholder='Enter Stripe Secret Key'
                    />
                  </Stack>
                </div>
              }

            </Card>

          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>

              <Typography variant="h6" sx={{ mb: 2 }}>
                Currency For Payments
              </Typography>

              <Typography sx={{ mb: 2 }} style={{ color: "#637381", fontSize: 13 }}>
                Choose a currency in which you wish to collect payments from your customers. This should be same as the currency on your Stripe account.
              </Typography>

              <RHFSelect
                name='config[][currency]'
                label='Currency'
                native
              >
                <option value={'USD'}>USD</option>
                <option value={'EUR'}>EUR</option>
                <option value={'GBP'}>GBP</option>
                <option value={'AUD'}>AUD</option>
                <option value={'CAD'}>CAD</option>
                <option value={'NZD'}>NZD</option>
                <option value={'JPY'}>JPY</option>
                <option value={'SGD'}>SGD</option>
                <option value={'HKD'}>HKD</option>
                <option value={'MXN'}>MXN</option>
                <option value={'CHF'}>CHF</option>
                <option value={'DKK'}>DKK</option>
                <option value={'NOK'}>NOK</option>
                <option value={'SEK'}>SEK</option>
                <option value={'ILS'}>ILS</option>
                <option value={'INR'}>INR</option>
                <option value={'PHP'}>PHP</option>
                <option value={'PLN'}>PLN</option>
                <option value={'THB'}>THB</option>
                <option value={'CZK'}>CZK</option>
                <option value={'HUF'}>HUF</option>
                <option value={'TWD'}>TWD</option>
                <option value={'TRY'}>TRY</option>
                <option value={'RUB'}>RUB</option>
                <option value={'BRL'}>BRL</option>
                <option value={'CNY'}>CNY</option>
                <option value={'IDR'}>IDR</option>
                <option value={'KRW'}>KRW</option>
                <option value={'MYR'}>MYR</option>
                <option value={'VND'}>VND</option>
                <option value={'ZAR'}>ZAR</option>
              </RHFSelect>
            </Card>
          </Grid>
        </Grid>}

      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        flexGrow={1}
        mt={2}
        mb={2}
      >
        <LoadingButton
          type="submit"
          size="medium"
          variant="contained"
          loading={isSubmitting}
        >
          Save Changes
        </LoadingButton>
      </Stack>

    </FormProvider>
  );
}
