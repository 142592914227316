import { alpha } from '@mui/material/styles';
import tinycolor from "tinycolor2";

export const BOOKING_STATUS_COLORS = {
  ALL: {
    background: 'rgba(0, 195, 255, 0.16)',
    color: 'rgb(0, 149, 183)',
  },
  PENDING: {
    background: 'rgba(255, 171, 0, 0.16)',
    color: 'rgb(183, 110, 0)',
  },
  APPROVED: {
    background: 'rgba(54, 179, 126, 0.16)',
    color: '#007B55',
  },
  REJECTED: {
    background: '#FF5630',
    color: '#FFFFFF',
  },
  NO_SHOW: {
    background: '',
    color: '',
  },
  COMPLETED: {
    background: '#00AB55',
    color: '#ffffff',
  },
  CANCELED: {
    background: 'rgba(255, 86, 48, 0.16)',
    color: 'rgb(183, 29, 24)',
  },
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const main_color = localStorage.getItem('primaryColor') || '#36b37e';

const PRIMARY = {
  lighter: tinycolor(main_color).lighten(50).toString(),
  light: tinycolor(main_color).lighten(20).toString(),
  main: main_color,
  dark: tinycolor(main_color).darken(20).toString(),
  darker: tinycolor(main_color).darken(50).toString(),
  contrastText: '#fff',
};

const secondary_color = localStorage.getItem('accentColor') || '#3366FF';

const SECONDARY = {
  lighter: tinycolor(secondary_color).lighten(50).toString(),
  light: tinycolor(secondary_color).lighten(20).toString(),
  main: secondary_color,
  dark: tinycolor(secondary_color).darken(20).toString(),
  darker: tinycolor(secondary_color).darken(50).toString(),
  contrastText: '#fff',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: '#fff',
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
